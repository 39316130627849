import React from 'react';
import Parallax from '../../../components/Parallax/Parallax';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

const ParallaxComponent = ({ classes }) => {
    return (
        <Parallax filter image={require('assets/img/keyboard.jpg')}>
            <div className={classes.container}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <h1 className={classes.title}>Ricardo D. García Navarro.</h1>
                        <h4>
                            'those who believe they can move mountains, do. Those who believe they can't,
                            cannot.'
                        </h4>
                        <span style={{color: 'white'}}>- David J. Schwartz, The Magic of Thinking Big.</span>
                    </GridItem>
                </GridContainer>
            </div>
        </Parallax>
    )
}

export default ParallaxComponent;
