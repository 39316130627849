import axios from 'axios'

export const TYPE = {
    PING: "PING",
    SOCIAL: "SOCIAL_CLICK",
    ABILITY: "ABILITY_CLICK",
    CERTIFICATION: "CERTIFICATION_CLICK",
    ROUTE: "LINK_CLICK",
    MESSAGE: "MESSAGE_LEFT"
}

function statistics(type = TYPE.PING, value = Date.now() ) {
    axios.post(`${process.env.REACT_APP_BACKEND_URL}api/statistics`, { type, value })
        .then()
        .catch()
}

export default statistics
