import React, { useState } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Tooltip from "@material-ui/core/Tooltip"
import Button from "../../components/CustomButtons/Button.jsx"
import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle.jsx"
import statistics, { TYPE } from '../../utilities/statistics'

const links = [
    {
        id: 'CV',
        tooltipText: "I'm very sorry! I haven't updated my PDF resume since a while 😰",
        href: '#',
        desktopTitle: 'See my PDF Resumé',
        mobileTitle: 'See my PDF Resumé',
        disabled: true,
        icon: 'fas fa-file-pdf'
    },
    {
        id: 'WhatsApp',
        tooltipText: "Send me a WhatsApp",
        href: 'https://wa.link/168h9k',
        desktopTitle: '',
        mobileTitle: 'Send me a WhatsApp',
        disabled: false,
        icon: 'fab fa-whatsapp'
    },
    {
        id: 'Email',
        tooltipText: "Send me an Email",
        href: 'mailto:ricardod_gn@hotmail.com?Subject=Hi%20Ricardo!%20I%20saw%20your%20web%20resume',
        desktopTitle: '',
        mobileTitle: 'Send me an Email',
        disabled: false,
        icon: 'fas fa-envelope'
    },
    {
        id: 'LinkedIn',
        tooltipText: "Connect to me in LinkedIn",
        href: 'https://www.linkedin.com/in/ricardo-daniel-garcia-navarro-151065128/',
        desktopTitle: '',
        mobileTitle: 'Connect to me in LinkedIn',
        disabled: false,
        icon: 'fab fa-linkedin-in'
    },
    {
        id: 'Github',
        tooltipText: "Follow me on GitHub",
        href: 'https://github.com/RichardGrac',
        desktopTitle: '',
        mobileTitle: 'Follow me on GitHub',
        disabled: false,
        icon: 'fab fa-github'
    }
]

function HeaderLinks({...props}) {
    const {classes} = props
    const [isDesktop] = useState(window.innerWidth >= 959)

    return (
        <List className={classes.list}>
            {links.map(link => (
                <ListItem className={classes.listItem}>
                    <Tooltip
                        id={`${link.id}-tooltip`}
                        title={link.tooltipText}
                        placement={isDesktop ? "top" : "left"}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <Button
                            color="transparent"
                            href={link.href}
                            target={!link.disabled && "_blank"}
                            rel={!link.disabled && "noopener noreferrer"}
                            className={classes.navLink}
                            style={link.disabled ? { color: '#AAA' } : {}}
                            onClick={() => statistics(TYPE.SOCIAL, link.id)}
                        >
                            <i className={`${classes.socialIcons} ${link.icon}`}/>
                            {isDesktop ? link.desktopTitle : link.mobileTitle}
                        </Button>
                    </Tooltip>
                </ListItem>
            ))}
        </List>
    )
}

export default withStyles(headerLinksStyle)(HeaderLinks)
