import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import GridContainer from '../../../components/Grid/GridContainer'
import GridItem from '../../../components/Grid/GridItem'

import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { title } from '../../../assets/jss/material-kit-react'
import Tags from './components/Tags'
import statistics, { TYPE } from '../../../utilities/statistics'

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        height: '100%'
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: 'center'
    }
});

const hasDecimal = number => {
    return (number - Math.floor(number)) !== 0
}

const getStars = (icons, stars) => {
    return [...Array(icons)]
        .map((_, i) => {
            return (icons === i+1 && hasDecimal(stars % 2)) ?
                (<i className="fas fa-star-half" key={i} />)
                : (<i className="fas fa-star" key={i} />)
        })
}

const getAbilities = (fromExperience) => {
    let abilitiesObj = {
        5: [
            {icon: 'fab fa-js', name: 'Javascript', color: '197, 175, 7', tags: 'Array Destructuring, Object Destructuring, Functions, Arrow Functions, Binding, Let, Const, Import, Require, Classes, Es6, Fetch, Axios'},
            {icon: 'fab fa-react', name: 'ReactJS', color: '0, 173, 204', tags: 'lifecycle Methods, Class Based Components, React Hooks, Function Components, PureComponents, Memo, Fragments,  Props, State, Immutability, Event Handling, RadiumJS, Aphrodite, Reactstrap, Conditional Rendering, Lists And Keys'},
            {icon: 'fas fa-arrows-alt-h', name: 'Responsive Web Design', color: '149, 126, 106', tags: 'Percentages, Media Queries, Device Detection'},
            {icon: 'fab fa-affiliatetheme', name: 'Jest', color: '16, 145, 14', tags: 'expect(), .toHaveBeenCalled(), .toBe(), .not(), .toHaveLength(), .toBeLessThan(), .toBeNaN(), .toContain(), .toEqual(), jest.fn(), mockClear(), .mock.calls, .mockReturnValue(val)'},
            {icon: 'fas fa-code', name: 'Typescript', color: '49, 120, 198' },
            {icon: 'fas fa-atom', name: 'GraphQL', color: '229, 53, 171', tags: ''},
            {icon: 'fab fa-asymmetrik', name: 'Apollo', color: '95, 24, 226', tags: ''},
            {icon: 'fab fa-node-js', name: 'NodeJS', color: '90, 170, 70', tags: ''},
        ],
        4.5: [
            {icon: 'fab fa-html5', name: 'HTML5', color: '228, 77, 38', tags: 'Header, Footer, Autofocus, Audio, Video, Preloading, Regexp, Custom Attributes, Input:range'},
            {icon: 'fab fa-css3', name: 'CSS3', color: '21, 114, 182', tags: 'variables, FlexBox, Box Shadowing'},
            {icon: 'fab fa-git', name: 'Git', color: '240, 80, 51', tags: 'Init, Pull, Push, Fetch, Checkout, Remote Add, Clone, Branch, Add, Commit, Help, Merge, Reset'},
            {icon: 'fas fa-business-time', name: 'SCRUM', color: '112, 90, 75', tags: 'Roles, Ceremonies, Artifacts, User Histories, Progress Charts, Acceptance Criteria, KPIs And Metrics'},
            {icon: 'fab fa-react', name: 'Redux', color: '110, 80, 181', tags: 'Store, Actions, Reducers, BindActionCreators, Thunk, Redux-saga, Generators, Redux Devtools, Connect, MapStateToProps, MapDispatchToProps, Dispatch'},
            {icon: 'fas fa-vial', name: 'Enzyme', color: '0, 120, 215', tags: 'Shallow rendering, Mount rendering, dive(), debug(), props(), setProps(), state(), setState(), setContext(), simulate(ev), text(), disableLifecycleMethods'},
            {icon: 'fas fa-database', name: 'PostgreSQL', color: '49, 97, 146'},
            {icon: 'fas fa-server', name: 'Cloud Deployment', color: '47, 53, 66'},
        ],
        4: [
            {icon: 'fab fa-bootstrap', name: 'Bootstrap', color: '86, 61, 124', tags: 'Container, Container-fluid, Row, D-flex, Col-x, Col-sm-x, Col-md-x, Col-lg-x, Col-xl-x, P-1, P-lg-x, M-1, Text-center'},
            {icon: 'fas fa-vr-cardboard', name: 'Material UI', color: '86, 61, 124', tags: 'Box, Container, Grid, Hidden, Menu, Button, Bottom Navigation, App Bar, Expansion Panel, Lazy Loading, Tooltip, Modal, Spacing, Typography, Icons, Richard Resumé 😏'},
            {icon: 'fab fa-microsoft', name: '.NET', color: '57, 82, 115', tags: 'POO, Lists, REST APIs, MVC, Elasticsearch, NUnit, StringBuilder, Arrays, Stacks, Queues, List<T>, Lambda Expressions, LINQ, Exceptions, Conditionals'},
            {icon: 'fab fa-angular', name: 'Angular2+', color: '221, 0, 49', tags: '@Component, Templates, Decorators, Services, Directives, Providers, TypeScript, Pipes, *ngIf, *ngFor, [ngSwitch], *ngSwitchCase, ngStyle, [ngClass], [(ngModel)], ngOnInit, RouterLink, RouterLinkActive, @angular/router, @angular/core, selector, templateUrl, Router.Navigate(), PipeTransform, transform(), @Injectable'},
            {icon: 'fab fa-git', name: 'JPA', color: '44, 58, 71'},
            {icon: 'fas fa-asterisk', name: 'API Rest', color: '87, 96, 111'},
            {icon: 'fas fa-window-restore', name: 'Analysis of Computer Systems', color: '64, 64, 122'},
        ],
        3.5: [
            {icon: 'fas fa-database', name: 'MySQL', color: '132, 129, 122'},
            {icon: 'fas fa-database', name: 'MS SQL Server', color: '#51768C'},
            // {icon: 'fab fa-linux', name: 'Linux', color: '47, 53, 66'},
            {icon: 'far fa-dot-circle', name: 'Ionic 3', color: '56, 128, 255'},
        ],
        3: [
            {icon: 'fab fa-java', name: 'Java', color: '247, 88, 42', tags: 'POO, ArrayLists, HashMap, LinkedList, Stack, Queue, Tree, Inheritance, Interface, Abstract Classes, Package, Class, Static, Wrappers, String'},
            {icon: 'fas fa-leaf', name: 'Spring', color: '46, 204, 113'},
            {icon: 'fab fa-css3', name: 'Hibernate', color: '207, 148, 23'},
            {icon: 'fab fa-react', name: 'Redux-Saga', color: '110, 80, 181'},
            {icon: 'fas fa-database', name: 'Google Firebase Database', color: '179, 57, 57'},
        ],

    }
    return abilitiesObj[fromExperience]
}

const iconColorExc = (abName) => {
    const exceptions = new Map()
    // In 'exceptions' I can add an Ability name with an special color for the Icon of it.

    let colorExc = exceptions.get(abName)
    return colorExc === undefined ? 'white' : colorExc
}

const Abilities = (props) => {
    const {classes} = props
    const [abilitiesStyle, setAbilitiesStyle] = useState(window.innerWidth < 400)
    const [tagsModal, setTagsModal] = useState({status: false, tags: null, tagsColor: null})

    useEffect(() => {
        const resizeListener = window.addEventListener('resize', () => {
            setAbilitiesStyle(window.innerWidth < 400)
        })
        return () => {
            resizeListener.removeEventListener('resize')
        }
    }, [])

    let stars = 5
    let content = []
    while(stars >= 3) {
        let icons = hasDecimal(stars % 2) ? stars + 0.5 : stars
        content.push(
            <GridItem xs={12} md={6} style={{margin: '8px auto'}} key={stars}>
                <Paper className={classes.root} elevation={1}>
                    <Typography variant="h5" component="h3">
                        {getStars(icons, stars)}
                    </Typography>
                    <Typography component="div" style={{marginTop: 5}}>
                        {getAbilities(stars).map((ability, i) => (
                            <Chip
                                id='modalBtn'
                                onClick={() => handleOpen(ability.name, ability.tags, ability.color)}
                                avatar={
                                <Avatar style={{
                                    backgroundColor: `rgb(${ability.color})`,
                                    color: iconColorExc(ability.name),
                                    width: 32,
                                    height: 31,
                                    marginLeft: 0
                                }}
                                >
                                    <i className={ability.icon} style={{fontSize: '15pt'}} />
                                </Avatar>}
                                  label={ability.name.length >= 15 && abilitiesStyle ?
                                      ability.name.substr(0, 14) + '...'
                                      : ability.name
                                  }
                                  variant="outlined"
                                  key={i}
                                  style={{margin: '7px 10px',
                                      fontSize: '12pt',
                                      color: 'rgb(44, 58, 71)',
                                      borderColor: 'rgb(44, 58, 71)',
                                      fontWeight: 500,
                                      backgroundColor: ability.tags ? `rgba(${ability.color}, 0.2)` : null
                                  }}
                            />
                        ))}
                    </Typography>
                </Paper>
            </GridItem>
        )
        stars -= 0.5
    }

    const handleOpen = (name, tags, tagsColor) => {
        statistics(TYPE.ABILITY, name);
        if (tags) {
            setTagsModal({status: true, tags, tagsColor})
        }
    }

    const handleClose = () => {
        setTagsModal({status: false, tags: null, tagsColor: null})
    }

    return (
        <React.Fragment>
            <GridContainer justify='center'>
                <GridItem xs={12} sm={12} md={12}>
                    <h2 className={classes.title}>Abilities</h2>
                    <hr/>
                </GridItem>
                {content.map(paper => paper)}
            </GridContainer>
            {tagsModal.status ? (
                <Tags handleClose={handleClose} tagsModal={tagsModal.tags} tagsColor={tagsModal.tagsColor} />
            ) : null}
        </React.Fragment>
    )
}

export default withStyles(styles)(Abilities)
