import GridContainer from '../../../components/Grid/GridContainer'
import GridItem from '../../../components/Grid/GridItem'
import { BACKEND_URL } from './SendMessage'
import { css, StyleSheet } from 'aphrodite'
import statistics, { TYPE } from '../../../utilities/statistics'
import React from 'react'

const achievementsStyles = StyleSheet.create({
    container: {
        margin: '0 auto'
    },
    card: {
        border: '1px solid #ccc',
        padding: '1rem',
        cursor: 'pointer',
        color: 'black',
        margin: '0.5rem 0',
        borderRadius: 10,
        display: 'block',
        ':hover': {
            backgroundColor: '#eee',
            fontWeight: 400,
        }
    },
    courseHours: {
        fontWeight: 400,
        fontSize: 'small'
    }
})

const certificates = [
    {
        /**
         * {id}: name of the file (for backend search)
         * {name}: for statistics
         * {completeName}: name displayed
         * {hours}: duration displayed
         * */
        id: 'UC-4LHQHPKX',
        name: 'ReactJS',
        completeName: 'React - The Complete Guide (incl Hooks, React Router, Redux)',
        hours: '40'
    },
    {
        id: 'UC-8DYAZ55K',
        name: 'MERN Stack',
        completeName: 'React, NodeJS, Express & MongoDB - The MERN Fullstack Guide',
        hours: '19'
    },
    {
        id: 'SCRUM-637977',
        name: 'PSM I',
        completeName: 'Professional Scrum Master',
        hours: '16'
    },
    {
        id: 'UC-126f7273-9908-4f02-b218-390d534e1d35',
        name: 'TypeScript',
        completeName: 'Understanding TypeScript - 2020 Edition',
        hours: '15'
    },
    {
        id: 'UC-W9ESRDE2',
        name: 'Ionic',
        completeName: 'Ionic 2/3 - Build iOS & Android Apps with Angular',
        hours: '14.5'
    },
    {
        id: 'UC-6d085395-7997-479c-a4ee-e2a5fe49204f',
        name: 'GraphQL w/React',
        completeName: 'GraphQL w/React: The Complete Developers Guide',
        hours: '13.5'
    },
    {
        id: 'UC-LAJP64BS',
        name: 'React Testing',
        completeName: 'React Testing with Jest and Enzyme',
        hours: '12'
    },
    {
        id: 'UC-YAZS1U1U',
        name: 'ASP.NET',
        completeName: 'ASP.NET MVC 5',
        hours: '7.5'
    },
    {
        id: 'UC-57a31df6-84a6-4df2-9c29-54458c658469',
        name: 'Clean Code',
        completeName: 'Clean Code',
        hours: '6.5'
    },
]

const Certificates = () => {
    return (
        <GridContainer className={css(achievementsStyles.container)}>
            {certificates.map(c => (
                <GridItem xs={12} md={6}>
                    <a href={`${BACKEND_URL}public/pdf/${c.id}.pdf`}
                       target='_blank'
                       rel="noopener noreferrer"
                       className={css(achievementsStyles.card)}
                       onClick={() => statistics(TYPE.CERTIFICATION, c.name)}
                    >
                        {c.completeName}{' '}
                        <b className={css(achievementsStyles.courseHours)}>({c.hours} hrs)</b>
                    </a>
                </GridItem>
            ))}
        </GridContainer>
    )
}
export default Certificates
