import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Router, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import LandingPage from "views/LandingPage/LandingPage.jsx";

import './globalStyle.css'
import statistics from './utilities/statistics'

let hist = createBrowserHistory();
statistics()

ReactDOM.render(
  <Router history={hist}>
    <Switch>
        <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
