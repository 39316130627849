import React from 'react'
import './BackToTop.css'

const BackToTop = () => {
    const scrollToTop = useBackToTop()
    return (
        <React.Fragment>
            <button title="Back to top" className="scroll" onClick={scrollToTop}>
                <span className="arrow-up fas fa-angle-up" />
            </button>
        </React.Fragment>
    )
}

export default BackToTop


export const useBackToTop = () => {
    let intervalId = 0
    function scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    function scrollToTop() {
        intervalId = setInterval(scrollStep, 8);
    }
    return scrollToTop
}