import React from 'react'
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import withStyles from '@material-ui/core/styles/withStyles'
import MyExperienceStyle from '../../../assets/jss/material-kit-react/views/landingPageSections/myExperienceStyle'

const TitleDescription = (props) => {
    const { classes, maxWidth } = props
    const width = maxWidth ? maxWidth : 8
    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <h2 className={classes.title}>{props.title}</h2>
                    <hr/>
                </GridItem>
                <GridItem xs={12} sm={12} md={width}>
                    <h5 className={classes.description}>
                        {props.description}
                    </h5>
                </GridItem>
            </GridContainer>
        </div>
    )
}

export default withStyles(MyExperienceStyle)(TitleDescription)
