import React, { Suspense, useRef, useState } from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx"

import MyExperienceStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/myExperienceStyle.jsx"
import GridItem from "../../../components/Grid/GridItem.jsx"
import Card from '../../../components/Card/Card'
import Slider from "react-slick"
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import kazooLogo from '../../../assets/img/kazoo.jpg'
import cristalOptics2Logo from '../../../assets/img/opticas-cristal.png'
import partsbaseLogo from '../../../assets/img/logo_color.454d4058.svg'
import smartAdminLogo from '../../../assets/img/SmartADMIN.png'
import pbtechprosLogo from '../../../assets/img/logo_pbtp.png'
import compuComLogo from '../../../assets/img/CompuCom.png'
import cristalOpticsLogo from '../../../assets/img/opticas_cristal.jpg'
import salesloftLogo from '../../../assets/img/Salesloft-Logo.jpeg'
import statistics, { TYPE } from '../../../utilities/statistics'

const ExperienceSlide = React.lazy(() => import('./components/ExperienceSlide'))

const MyExperience = (props) => {
    const [isPlaying, setIsPlaying] = useState(true)

    const [settings, ] = useState({
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    })
    const slider = useRef(null)

    const playPause = () => {
        const nextStatus = !isPlaying
        setIsPlaying(nextStatus)
        if (nextStatus) {
            slider.current.slickPlay()
        } else {
            slider.current.slickPause()
        }
    }

    /**
     * Used for "actual experience", e.g.:
     * from August - getMonthString(now)
     * */
    // const getMonthString = (month) => {
    //     const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    //         'October', 'November', 'December']
    //     return months[month]
    // }

    const handleRoute = (routeUrl) => {
        return {
            onClick: () => statistics(TYPE.ROUTE, routeUrl),
            href: routeUrl,
            target: '_blank',
            rel:'noopener noreferrer'
        }
    }

    const {classes} = props

    return (
        <div className={classes.section}>

            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <h2 className={classes.title}>My Experience</h2>
                    <hr/>
                </GridItem>
            </GridContainer>

            <GridContainer justify="center">

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <Slider ref={slider} {...settings}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ExperienceSlide
                                    imgSrc={salesloftLogo}
                                    alt={'img-salesloft'}
                                    when={`August (2021) - now`}
                                    style={{ maxWidth: 250 }}
                                >
                                    <div>
                                        Currently working in this project as a Fullstack Engineer, being involved in not
                                        only the development of the platform but also participating in the deployment of
                                        our projects and decision-making processes.
                                        <br/>
                                        <br/>
                                        <b>Technologies used:</b> Typescript, Javascript, ReactJS, Apollo, GraphQL,
                                        NodeJS, Postgres, AWS Web Services, Kafka, GCP, Kubernetes, and integration of external services through APIs.
                                        <br/>
                                        <b>Project: </b>
                                        <a {...handleRoute('https://salesloft.com/')}>
                                            https://salesloft.com/
                                        </a>
                                    </div>
                                </ExperienceSlide>
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ExperienceSlide
                                    imgSrc={kazooLogo}
                                    alt={'img-kazoohr'}
                                    when={`January (2020) - August (2021)`}
                                    style={{ maxWidth: 250 }}
                                >
                                    <div>
                                        Creation of the new Kazoo web (Performance Management app) that helps to create
                                        a more engaged workforce by making performance, recognition, and feedback part
                                        of every day.
                                        <br/>
                                        I worked in this project as a Fullstack Javascript Developer
                                        using very strictly programming practices and techniques that let us take
                                        the most advantage of each technology used.
                                        <br/>
                                        <br/>
                                        <b>Technologies used:</b> HTML, CSS, Typescript, Javascript, ReactJS, Apollo Client,
                                        Apollo Server Express, GraphQL, NodeJS, Postgres and internal libraries.
                                        <br/>
                                        <b>Project: </b>
                                        <a {...handleRoute('https://www.kazoohr.com/')}>
                                            https://www.kazoohr.com/
                                        </a>
                                    </div>
                                </ExperienceSlide>
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ExperienceSlide
                                    imgSrc={cristalOptics2Logo}
                                    alt={'img-optics-cristal'}
                                    when={`March - December (2020)`}
                                    style={{ maxWidth: 280 }}
                                >
                                    <div>
                                        The second part of a freelance project for an Optics. It's not a continuation
                                        but a new application for the administration of their inventory, sales, providers,
                                        and users of the system. Capabilities to do advanced searches of Lens Frames and
                                        Sales as well as the ability to generate a PDF with the different Search Results
                                        available.
                                        <br/>
                                        <br/>
                                        <b>Technologies used:</b> HTML, CSS, Javascript, ReactJS, Apollo, GraphQL, NodeJS, MongoDB Atlas and Heroku.
                                        <br/>
                                        <b>Project: </b>
                                        <a {...handleRoute('https://opticas-cristal.herokuapp.com/')}>
                                            https://opticas-cristal.herokuapp.com/
                                        </a>
                                        <br/>
                                        <b>Project Images: </b>
                                        <a {...handleRoute('https://drive.google.com/drive/folders/1_Pd1oQ5OcJL4nv6QY5BS3L7YssR-zcRD?usp=sharing')}>
                                            https://drive.google.com/drive/folders/1_Pd...
                                        </a>
                                    </div>
                                </ExperienceSlide>
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ExperienceSlide
                                    imgSrc={partsbaseLogo}
                                    alt={'img-pb20'}
                                    when={`August (2018) - December (2019)`}
                                >
                                    <div>
                                        Member in the creation of PartsBase v2.0 application (React, Redux, R. Router).
                                        <br/>
                                        Responsive Web Design (RWD) of many modules.
                                        Training to the teams about React Hooks.
                                        Use of .NET Web API for the consume of REST services.
                                        Creation of the "Parts Watch" system, which is a service that allows you to
                                        monitor changes that an article suffers through time (price, quantity).
                                        Creation of new and better email templates with a good approach to RWD focused on Outlook users.
                                        Participation in a Bidding System (frontend) where you can bid for advertising slots in order
                                        to appear in a Search results page.

                                        <br/>
                                        <br/>
                                        <b>Technologies used:</b> HTML, CSS, JS, ReactJS, C#, MS SQL Server Elastic Search, Webpack,
                                        Babel,
                                        Bootstrap and Material UI.
                                        <br/>
                                        <b>Project: </b>
                                        <a {...handleRoute('https://www.partsbase.com/')}>
                                            https://www.partsbase.com/
                                        </a>
                                    </div>
                                </ExperienceSlide>
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ExperienceSlide
                                    imgSrc={smartAdminLogo}
                                    alt={'img-smartADMIN'}
                                    when={'Jan - Dec (2018)'}
                                >
                                    <div>
                                        This mobile application was the biggest project made in University time.
                                        Together with a team, we demonstrate a software solution with
                                        the entire business model necessary to complete a
                                        project related to the IoT, specifically we implemented, as a main concept,
                                        a solution for illumination management through a mobile app and an
                                        approach of what we used to call "Smart houses".
                                        <br/>
                                        <br/>
                                        <b>Technologies used:</b> Ionic 3, Angular2+, HTML, CSS, Typescript and Firebase as a
                                        Real Time Database.
                                        <br/>
                                        <b>Project: </b>
                                        <a {...handleRoute('https://smartadminproject.herokuapp.com/')}>
                                            https://smartadminproject.herokuapp.com/
                                        </a>
                                        &nbsp;&nbsp;
                                        <Tooltip
                                            title="Want to try it? use credentials: 'test - testtest' to have a Premium Account"
                                            placement="top">
                                            <i className="fas fa-question-circle"/>
                                        </Tooltip>
                                        <br/>
                                        <b>Project Images: </b>
                                        <a {...handleRoute('https://drive.google.com/drive/folders/1AqRRDSWEwV1dp4Zdx9VOv1aby__-Xx8a?usp=sharing')}>
                                            https://drive.google.com/drive/folders/1A...
                                        </a>
                                    </div>
                                </ExperienceSlide>
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ExperienceSlide
                                    imgSrc={pbtechprosLogo}
                                    alt={'img-pbtechpros'}
                                    when={'July - August (2018)'}
                                >
                                    <div>
                                        This page front end was made for a work partner and me.
                                        <br/>
                                        Involved with the front end development, I used to get the mock ups
                                        from our company designer and start coding it.
                                        Actual design has been changed a little but a lot of what I did is still
                                        there.
                                        <br/>
                                        <br/>
                                        <b>Technologies used:</b> HTML, CSS, JS, jQuery and
                                        Bootstrap as a fast-development markup.
                                        <br/>
                                        <b>Project: </b>
                                        <a {...handleRoute('https://pbtechpros.com/')}>
                                            https://pbtechpros.com/
                                        </a>
                                    </div>
                                </ExperienceSlide>
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ExperienceSlide
                                    imgSrc={compuComLogo}
                                    alt={'img-CompuCom'}
                                    when={'2017'}
                                >
                                    <div>
                                        This was a scholar project which was created as an Online Store, where a lot of
                                        Hardware products
                                        were available. The system has a complete shopping process, from the selection
                                        of the products until
                                        the payment successful process.
                                        <br/>
                                        It has an Administration dashboard, were you can manage your employees, products
                                        available, status
                                        of the shipments and also all the sales you have done. And even more...
                                        <br/>
                                        <br/>
                                        <b>Technologies used:</b> Java, Spring, Hibernate, JPA, Thymeleaf, MySQL,
                                        Bootstrap 3.
                                        <br/>
                                        <b>Project Images: </b>
                                        <a {...handleRoute('https://drive.google.com/drive/folders/15HlsUlIG2WqitM-SxeZCmRg1QmiGQqK0?usp=sharing')}>
                                            https://drive.google.com/drive/folders/15...
                                        </a>
                                    </div>
                                </ExperienceSlide>
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <ExperienceSlide
                                    imgSrc={cristalOpticsLogo}
                                    alt={'img-opticasCristal'}
                                    when={'2016'}
                                >
                                    <div>
                                        A freelance project for an Optics. It was a small project for the administration
                                        of his users, clients, clinical records and the possibility of printing them.
                                        <br/>
                                        It is located in Aguascalientes Downtown, at street José Maria Chávez.
                                        <br/>
                                        <br/>
                                        <b>Technologies used:</b> Java, Spring, Hibernate, JPA, Thymeleaf, MySQL,
                                        Bootstrap 3.
                                        <br/>
                                    </div>
                                </ExperienceSlide>
                            </Suspense>
                        </Slider>

                        <Fab size="small"
                             aria-label={isPlaying ? "Pause Carousel" : "Play Carousel"}
                             style={{
                                 margin: '0 2% 15px auto',
                                 position: 'absolute',
                                 right: '0',
                                 bottom: '0',
                                 zIndex: 4
                             }}
                             onClick={() => playPause()}
                        >
                            <i className={isPlaying ? "fas fa-pause" : "fas fa-play"}/>
                        </Fab>
                    </Card>
                </GridItem>

            </GridContainer>
        </div>
    )
}

export default withStyles(MyExperienceStyle)(MyExperience)
