import React, { Fragment } from 'react'
import { StyleSheet, css } from 'aphrodite'
import './Tags.css'

const Tags = (props) => {
    const {handleClose, tagsModal, tagsColor} = props

    const verifyIfClickInBackdrop = (e) => {
        const backdrop = document.getElementById('backdrop')
        if (e.target === backdrop) {
            handleClose()
        }
    }

    const footerHover = StyleSheet.create({
        hoverAndFocus: {
            ':hover': {
                backgroundColor: `rgba(${tagsColor} , 0.1)`
            },
            ':focus': {
                backgroundColor: `rgba(${tagsColor} , 0.1)`
            }
        }
    })

    return (
        <Fragment>
            <div id='backdrop' className='modal' onClick={verifyIfClickInBackdrop}>
                <div className='modal-content'>
                    <div className='modal-header' style={{backgroundColor: `rgba(${tagsColor}, 0.9)`}}>
                        <span className='closeBtn' onClick={handleClose}>&times;</span>
                        <h3 className='less-margin'>Tags</h3>
                    </div>
                    <div className='modal-body'>
                        <p>
                            {tagsModal.replace(/\s/g, '').split(',').map((tag, i) => {
                                const isPrimeNumber = ((i+1) % 2) === 0 ? {color: `rgba(${tagsColor}, 0.7)`} : {color: 'rgba(0, 0, 0, 0.7)'}
                                return (
                                <span className='tag' key={i} style={isPrimeNumber}>
                                    #{tag}&nbsp;
                                </span>)
                            })}
                        </p>
                    </div>
                    <div className={css(footerHover.hoverAndFocus) + ' modal-footer'} onClick={() => handleClose()}>
                        <span role='img' aria-label='interesting'>Interesting&nbsp;😜</span>
                    </div>
                </div>
            </div>
        </Fragment>

    )
}

export default Tags