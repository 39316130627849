import React from 'react'
import GridContainer from '../../../components/Grid/GridContainer'
import {title} from '../../../assets/jss/material-kit-react'
import GridItem from '../../../components/Grid/GridItem'
import {makeStyles} from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    description: {
        color: "#404040",
        fontWeight: 500,
        marginTop: '4%'
    },
});

const cTitle = {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: 'center'
    }

const SoftSkills = () => {
    const classes = useStyles()
    return (
        <GridContainer justify='center'>
            <GridItem xs={12} sm={12} md={12}>
                <h2 style={cTitle}>Soft skills</h2>
                <hr/>
                <div className={classes.root}>
                    <h5 className={classes.description}>Teamwork, Respect, Professionalism.</h5>
                    <LinearProgress variant="determinate" value={100} />

                    <h5 className={classes.description}>Empathy, Commitment, Hard worker.</h5>
                    <LinearProgress variant="determinate" value={90} />

                    <h5 className={classes.description}>Resilience, Focus on Results.</h5>
                    <LinearProgress variant="determinate" value={80} />
                </div>
            </GridItem>
        </GridContainer>
    )
}

export default SoftSkills
