import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
// core components
import Header from '../../components/Header/Header.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import HeaderLinks from '../../components/Header/HeaderLinks.jsx';

import landingPageStyle from '../../assets/jss/material-kit-react/views/landingPage.jsx';
// Sections for this page
import MyExperience from './Sections/MyExperience.jsx';
import ThisIsMe from './Sections/ThisIsMe.jsx';
import SendMessage from './Sections/SendMessage.jsx';
import TitleDescription from './Sections/Objective'
import Abilities from './Sections/Abilities'
import SoftSkills from './Sections/SoftSkills'
import Certificates from './Sections/Certificates'
import ParallaxComponent from './Sections/ParallaxComponent'

const dashboardRoutes = [];

const LandingPage = (props) => {
    const { classes, ...rest } = props
    return (
        <div>
            <Header
                color='transparent'
                routes={dashboardRoutes}
                brand='Ricardo García'
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 450,
                    color: 'customBlue'
                }}
                {...rest}
            />
            <ParallaxComponent classes={classes} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <TitleDescription title={'Objective'}
                                      description={'Be involved as part of the Software Development team of any ' +
                                      'organization so that way I can offer solutions to problems using cutting-edge ' +
                                      'software tools, no matter the goal or role of the business'}
                    />
                    <TitleDescription title={'Profile'}
                                      description={'Software engineer oriented mainly to web development. ' +
                                      'Analysis, development, and implementation of requirements with the best ' +
                                      'coding and development practices. Ability to work in several teams with a ' +
                                      'focus on priorities. A passionate for the Responsive Web Design and ' +
                                      'User Interfaces, accomplishing the satisfaction of clients throughout ' +
                                      'good UIs with great user experience'}
                    />
                    <Abilities/>
                    <SoftSkills/>
                    <MyExperience/>
                    <TitleDescription
                        maxWidth={11}
                        title={<span>Certificates&nbsp;<i className='far fa-file-pdf' /></span>}
                        description={<Certificates />}
                    />
                    <ThisIsMe/>
                    <SendMessage/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}


export default withStyles(landingPageStyle)(LandingPage);
