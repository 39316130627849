import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";

import ThisIsMeStyle from '../../../assets/jss/material-kit-react/views/landingPageSections/ThisIsMeStyle.jsx';
import myPicture from '../../../assets/img/Me.jpg';

// @material-ui/icons

const interests = {
    'Exercise': 'fas fa-dumbbell',
    'Healthy lifestyle': 'fas fa-heartbeat',
    'Code': 'fas fa-code',
    'Music': 'fas fa-headphones-alt',
    'Coffee': 'fas fa-coffee',
    'Video games': 'fas fa-gamepad',
    'Friends': 'fas fa-glass-cheers',
    'Investments': 'fas fa-chart-line',
    'Audiobooks': 'fab fa-audible'
}

const ThisIsMe = (props) => {
    const [interestsStyle, setInterestsStyle] = useState(window.innerWidth > 760 ? 'flex' : 'block')
    useEffect(() => {
        const resizeListener = window.addEventListener('resize', () => {
            setInterestsStyle(window.innerWidth > 760 ? 'flex' : 'block')
        })
        return () => {
            resizeListener.removeEventListener('resize')
        }
    }, [])

    function calculateAge(birthday) {
        let ageDifMs = Date.now() - birthday.getTime();
        let ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const {classes} = props;
    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid,
        classes.imgMargin
    );
    return (
        <React.Fragment>
            <div className={classes.section}>

                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                        <h2 className={classes.title}>This is me</h2>
                        <hr/>
                    </GridItem>
                </GridContainer>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={8} className={classes.itemGrid}>
                        <h4 className={classes.cardTitle}>
                            A Computer Systems Engineer <span role='img' aria-label='sunglasses-emoji'>😎</span>
                        </h4>
                        <h5 className={classes.description}>
                            I am Ricardo Garcia, everyone tells me Richard.
                            <br />
                            I'm {calculateAge(new Date('12/21/1995 00:00:00'))} years old (my birthday is December 21) and I am a Computer Systems Engineer.
                            <br/>
                            I studied my degree in Autonomous University of Aguascalientes (now B.U.A.A.)
                            from August, 2013 to December, 2018.
                            <br/>
                            I don't miss school, but I really like to stay always learning something new by myself.
                        </h5>

                        <h4 className={classes.cardTitle} style={{marginTop: '25px'}}>
                            How I describe myself?
                        </h4>
                        <h5 className={classes.description}>
                            I am a disciplined person with a very
                            high degree of intrinsic motivation.
                            Proactive but able to solve problems
                            once they have occurred. Enthusiast
                            of new technologies and trendies.
                            Passionate always to reach new
                            goals and achieve personal
                            improvement.
                        </h5>

                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} className={classes.itemGrid}>
                        <img src={myPicture}
                             alt="myPicture"
                             className={imageClasses}
                        />
                    </GridItem>
                </GridContainer>
            </div>

            <div className={classes.section} style={{marginTop: '3%', paddingBottom: '1rem'}}>
                <GridContainer justify='center'>
                    <GridItem xs={12}>
                        <h4 className={classes.cardTitle}>
                            Interests
                        </h4>
                        <div style={{display: '-webkit-box'}}>
                            <div className={classes.interestsRow} style={{display: interestsStyle}}>
                                {Object.keys(interests).map((interest, index) => (
                                    <h5 className={classes.interestsIcons} key={index}>
                                        {interest}
                                        <br/>
                                        <i className={interests[interest]}/>
                                    </h5>
                                ))}
                            </div>
                        </div>
                    </GridItem>
                </GridContainer>

                <GridContainer justify='center'>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <h4 className={classes.cardTitle}>
                            Languages
                        </h4>
                        <h5 className={classes.interestsIcons}>
                            Spanish | English
                        </h5>
                    </GridItem>
                </GridContainer>
            </div>


        </React.Fragment>
    );
}

export default withStyles(ThisIsMeStyle)(ThisIsMe);
