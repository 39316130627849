import { title } from "../../../material-kit-react.jsx";
import { text } from '../../../material-kit-react'

const MyExperienceStyle = {
  section: {
    padding: "2% 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: text.color
  },
  cardsPadding: {
    padding: '20px 10px 40px 10px'
  },
  whenTitle: {
    fontSize: '14pt'
  },
  imageSizing: {
    maxWidth: '350px',
    width: '80%',
    height: 'auto',
    margin: '0 auto'
  }
};

export default MyExperienceStyle;
