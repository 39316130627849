import React, { useEffect, useState } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import axios from 'axios'

import GridContainer from "../../../components/Grid/GridContainer.jsx"
import GridItem from "../../../components/Grid/GridItem.jsx"
import CustomInput from "../../../components/CustomInput/CustomInput.jsx"
import Button from "../../../components/CustomButtons/Button.jsx"

import sendMessageStyle from "../../../assets/jss/material-kit-react/views/landingPageSections/sendMessageStyle.jsx"
import BackToTop from './components/BackToTop'
import SnackbarContent from '../../../components/Snackbar/SnackbarContent'
import { Check } from '@material-ui/icons'
import { TYPE } from '../../../utilities/statistics'

const emailRegex = new RegExp(/^[-A-Z-a-z0-9~!$%^&*_=+}{\'?]+(\.[-A-Z-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/)

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const SendMessage = (props) => {
    const [name, setName] = useState(undefined)
    const [email, setEmail] = useState(undefined)
    const [message, setMessage] = useState(undefined)
    const [snackbarStatus, setSnackbarStatus] = useState({
        showSnackbar: false,
        vertical: 'bottom',
        horizontal: 'left',
        status: 'success',
        title: 'Message Received',
        description: 'Thank you so much, the message is now with me. I\'ll check it as soon as possible :)'
    })
    const [isSendingMessage, setIsSendingMessage] = useState(false)

    useEffect(() => {
        if (isSendingMessage) {
            const data = {
                name: name,
                email: email,
                message: message,
                // Statistics data
                type: TYPE.MESSAGE
            }

            let title = 'Message Received'
            let description = 'Thank you so much, the message is now with me. I\'ll check it as soon as possible 😃'
            let status = 'success'


            axios.post(`${BACKEND_URL}api/messages`, data)
                .then(() => {
                    setName(undefined) // Check if it's better to initialize in undefined
                    setEmail(undefined)
                    setMessage(undefined)
                })
                .catch(() => {
                    title = 'Ops!'
                    description = 'There was a problem while saving the message. For me it is important to know your message, ' +
                        'please contact me by another channel'
                    status = 'danger'
                })
                .finally(() => {

                    setSnackbarStatus({
                        ...snackbarStatus,
                        showSnackbar: true,
                        status,
                        title,
                        description
                    })
                    setIsSendingMessage(false)

                    setTimeout(() => {
                        setSnackbarStatus({
                            ...snackbarStatus,
                            showSnackbar: false
                        })
                    }, 12000)
                })
        }
    }, [isSendingMessage])

    const postAMessage = async (e) => {
        e.preventDefault()
        if(isSendMessageDisabled()) return
        else setIsSendingMessage(true)
    }

    const {classes} = props

    function isSendMessageDisabled() {
        // Success validations:
        return !(name !== undefined && name.trim().length >= 1 &&
        email !== undefined && email.trim().length >= 1 && emailRegex.test(email) &&
        message !== undefined && message.trim().length >= 1)
    }

    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem cs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Send me a Message</h2>
                    <h4 className={classes.description}>
                        After all above, I appreciate your time spent reading my online resumé.
                        Leave me a message whatever is the purpose.
                        <br/>
                        Thank you!
                    </h4>
                    <form onSubmit={postAMessage}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Your Name"
                                    id="name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: name || '',
                                        onChange: (e) => setName(e.target.value)
                                    }}
                                    error={name !== undefined && name.trim().length < 1}
                                    success={name !== undefined && name.trim().length >= 1}
                                    disabled={isSendingMessage}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    labelText="Your Email"
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        value: email || '',
                                        onChange: (e) => setEmail(e.target.value)
                                    }}
                                    error={email !== undefined && (email.trim().length < 1 || !emailRegex.test(email))}
                                    success={email !== undefined && email.trim().length >= 1 && emailRegex.test(email)}
                                    disabled={isSendingMessage}
                                />
                            </GridItem>
                            <CustomInput
                                labelText="Your Message"
                                id="message"
                                formControlProps={{
                                    fullWidth: true,
                                    className: classes.textArea
                                }}
                                inputProps={{
                                    multiline: true,
                                    rows: 5,
                                    value: message || '',
                                    onChange: (e) => e.target.value.length <= 400 ? setMessage(e.target.value) : {}
                                }}
                                error={message !== undefined && message.trim().length < 1}
                                success={message !== undefined && message.trim().length >= 1}
                                disabled={isSendingMessage}
                            />
                            <GridContainer justify="center"
                                           style={{paddingLeft: '4%'}}>
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    className={classes.textCenter}>
                                    <Button color='rose'
                                            style={isSendingMessage ? {cursor: 'wait'} : {cursor: 'pointer'}}
                                            type={'submit'}
                                            title={isSendingMessage ? 'Sending the message...' : 'Send message'}
                                            disabled={isSendMessageDisabled() || isSendingMessage}
                                    >
                                        Send Message&nbsp;&nbsp;<span style={{fontSize: 16}} role='img' aria-label='fantastic-emoji'>👌</span>
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridContainer>
                    </form>
                </GridItem>

                <BackToTop/>

            </GridContainer>

            {snackbarStatus.showSnackbar ? (
                <SnackbarContent
                    message={
                        <span><b>{snackbarStatus.title}:</b>{snackbarStatus.description}</span>
                    }
                    close
                    color={snackbarStatus.status}
                    icon={Check}
                    snackbarStatus={snackbarStatus}
                    setSnackbarStatus={setSnackbarStatus}
                />
            ) : null}

        </div>
    );
}

export default withStyles(sendMessageStyle)(SendMessage);
